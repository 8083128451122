import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { RoundedBox } from '@react-three/drei';
import { Grid, Typography, Button, Box , Container} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

function Cube({ position, scale, speed, stopTime, isRotating }) {
  const mesh = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(position);
  const [isHovered, setIsHovered] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { clock } = useThree();

  // Stop animation after stopTime seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Animation and rotation handling
  useFrame(() => {
    if (mesh.current && isRotating) {
      // Apply rotation to all cubes if isRotating is true
      mesh.current.rotation.x += 0.05;
      mesh.current.rotation.y += 0.05;
    }

    // Wavy movement if not hovered and within the stop time
    if (!isHovered && elapsedTime < stopTime) {
      const time = clock.getElapsedTime();
      const offsetX = Math.sin(time * speed) * 3;
      const offsetY = Math.cos(time * speed) * 3;
      setCurrentPosition([position[0] + offsetX, position[1] + offsetY, position[2]]);
    }
  });

  return (
    <mesh
      ref={mesh}
      position={currentPosition}
      scale={scale}
      onPointerOver={() => setIsHovered(true)}
      onPointerOut={() => setIsHovered(false)}
    >
      <RoundedBox args={[1, 1, 1]} radius={0.2} smoothness={8}>
        <meshStandardMaterial color="#86ccfe" metalness={0} roughness={1} />
      </RoundedBox>
    </mesh>
  );
}

function App() {
  const gridSize = 4;
  const cubeSize = 0.8;
  const spacing = cubeSize + 0.1;
  const stopTime = 3;
  const cubePositions = [];
  const [isRotating, setIsRotating] = useState(false); // Global state for rotation

  // Generate cube positions
  for (let x = 0; x < gridSize; x++) {
    for (let y = 0; y < gridSize; y++) {
      for (let z = 0; z < gridSize; z++) {
        cubePositions.push([
          x * spacing - (gridSize * spacing) / 2 + spacing / 2,
          y * spacing - (gridSize * spacing) / 2 + spacing / 2,
          z * spacing - (gridSize * spacing) / 2 + spacing / 2,
        ]);
      }
    }
  }

  // Toggle rotation for all cubes
  const handleCubeClick = () => {
    setIsRotating(!isRotating); // Toggle the global rotation state
  };

  return (
    <Grid 
  container 
  style={{ 
    height: '80vh', // Adjusted height to reduce overall grid height
    width: '100%', 
    background: 'linear-gradient(#71d5db, #e2e7b8)' 
  }}
>
  {/* Centered About Us Heading */}
  <Grid 
    item 
    xs={12} 
    style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      paddingTop: '10px', 
      marginTop: '20px' 
    }}
  >
    <Typography 
      variant="h2" 
      color="Black" 
      gutterBottom 
      sx={{ 
        textAlign: 'center', 
        fontWeight: 600, 
        color: '#907394', 
        marginBottom: '12px',
        
      }}
    >
      About Us
    </Typography>
  </Grid>

  <Grid container>
    <Grid
      item
      xs={12}
      md={6}
      style={{
        padding: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography 
        variant="h3" 
        color="Black" 
        gutterBottom 
        sx={{ 
          textAlign: 'left', 
          marginBottom: '16px',
          marginTop: { md: -20, xs: 2, sm: 0 }, // Adjusted top margin
        }}
      >
        Your Digital Success Partner
      </Typography>
      <Typography 
        variant="body1" 
        color="Black" 
        sx={{ 
          textAlign: 'left', 
          marginBottom: '16px', 
          fontSize: '18px' 
        }}
      >
        GetMoreDigital turns your idea into a reality by utilizing powerful business research and state-of-the-art digital marketing strategies.
      </Typography>
      <Typography 
        variant="body1" 
        color="Black" 
        sx={{ 
          textAlign: 'left', 
          marginBottom: '24px', 
          fontSize: '18px' 
        }}
      >
        From captivating ads to insights gleaned from data, we are committed to enhancing your brand and producing quantifiable outcomes.
      </Typography>
      <Box sx={{ textAlign: 'left' }}>
        <a href="https://www.youtube.com/@GetMyCollege_official" target="_blank" rel="noopener noreferrer">
          <Button
            variant="outlined"
            sx={{
              color: '#4b87a6',
              borderColor: '#4b87a6',
              fontWeight: 'bold',
              padding: '10px 20px',
              borderRadius: '30px',
              backgroundColor: '#ebebeb',
            }}
          >
            <PlayCircleFilledWhiteIcon sx={{ color: '#4b87a6', mr: 1 }} />
            Watch Our Story
          </Button>
        </a>
      </Box>
    </Grid>

    <Grid item xs={12} md={6} mt={{ md: -10, sm: -7, xs: -2 }}>
      <Canvas
        style={{ width: '100%', height: '90vh' }} // Reduced canvas height
        camera={{ position: [0, 0, 10], fov: 75 }}
        onClick={handleCubeClick}
      >
        <ambientLight intensity={0.2} />
        <pointLight position={[10, 10, 10]} intensity={0.8} />
        <directionalLight position={[-5, -5, 10]} intensity={0.4} />
        {cubePositions.map((position, index) => (
          <Cube
            key={index}
            position={position}
            scale={[cubeSize, cubeSize, cubeSize]}
            speed={0.05 + index * 0.2}
            stopTime={stopTime}
            isRotating={isRotating}
          />
        ))}
      </Canvas>
    </Grid>
  </Grid>
</Grid>


  );
}

export default App;
