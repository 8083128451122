import React from 'react'
import Header1 from '../../../component/Page/Header1'
import Bannerpage from './Bannerpage'
import Footer from '../../Page/Footer'
import Coursesdetail from './Coursesdetail'
import Graphicscards from './Graphicscards'
import Popular from './Popular'
import Review from './Review'

const Homepage = () => {
  return (
    <div>
      <Header1/>
      <Bannerpage/>
      <Graphicscards/>
      <Popular/>    
      <Review/>
      {/* <Coursesdetail/> */}
      <Footer/>
    </div>
  )
}

export default Homepage
