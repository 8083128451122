import React from "react";
import { Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Image Imports
import banner1 from "../../../assets/Graphic/banner3.png"; // Replace with your images
import banner2 from "../../../assets/Graphic/girl3.png";
import banner3 from "../../../assets/Graphic/Girl2.png";

const Homepage = () => {
  const backgroundImages = [banner1, banner2, banner3];

  // Carousel settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true, // Smooth fade effect for background
  };

  return (
    <div
      style={{
        position: "relative",
        height: "80vh",
        overflow: "hidden",
      }}
    >
      {/* Background Carousel */}
      <Slider {...settings}>
        {backgroundImages.map((image, index) => (
          <div key={index}>
            <div
              style={{
                background: `url(${image}) no-repeat center center`,
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
              }}
            ></div>
          </div>
        ))}
      </Slider>

      {/* Content Section */}
      <Grid
        container
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          textAlign: "center",
          color: "#fff",
          p: 3,
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "32px", sm: "36px", md: "40px" },
              fontWeight: 600,
              color: "#e349d4",
            }}
          >
            Graphic Designing Course
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              mt: 4,
              fontWeight: 600,
              color: "#E0E0E0",
            }}
          >
            With the help of our Graphic Design Course which covers tools,
            techniques, and innovative concepts, you may become an expert
            designer. With practical instruction, you can enhance your career
            and create stunning visuals.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Homepage;
