import { Grid, Box, Button, Card, Typography, TextField, Modal } from '@mui/material';
import React, { useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const WaveSVG = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({ name: '', phoneNumber: '' });

  const handleOpen = () => setOpen(true); // Opens the modal
  const handleClose = () => {
    setOpen(false); // Closes the modal
    setErrors({ name: '', phoneNumber: '' }); // Reset errors on close
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = 'Name is required';
    if (!phoneNumber.trim()) newErrors.phoneNumber = 'Phone Number is required';
    // else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(phoneNumber)) newErrors.phoneNumber = 'Invalid Phone Number ';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleViewPDF("");
    }
  };

  const handleViewPDF = (url) => {
    window.open(url, "_blank"); // Open the PDF in a new tab
    handleClose(); // Close the modal after the download starts
  };

  return (
    <Grid
      sx={{
        width: '100%',
        height: {md:'50vh', sm:'50vh', xs:'100vh'},
        overflow: 'hidden',
        backgroundColor: '#D9EEE1',
        mt: 1,
      }}
    >
      <svg
        width="100%"
        height="70"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ backgroundColor: '#D9EEE1' }}
      >
        <path d="M0,0 L110,0 C55,200 35,0 0,90z" fill="#fff" />
      </svg>
      <Grid container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'none',
            width: '100%',
            height: {md:'30vh', sm:'30vh', xs:'80vh'},
            mt:5
          }}
        >
          <Card
            sx={{
              padding: 2,
              boxShadow: 3,
              textAlign: 'center',
              backgroundColor: '#f0f3f7',
              height: '90%',
              width: '90%',
             
            }}
          >
            <Typography variant='h4' sx={{textAlign:'left', ml:5, color:'#7e5980', fontWeight:600, mt:1}}> Start Your Knowledge Adventure</Typography>
          <Typography variant='body1' sx={{p:5, fontSize:'18px', textAlign:'left', mt:-2}}> To assist you make an informed decision about your educational journey you may access comprehensive course syllabi to examine the subjects, goals, and learning outcomes for each program.</Typography>
            <Button
              variant="contained"
              onClick={handleOpen} // Opens the modal on button click
              sx={{ textTransform: 'none' , fontSize:'20px', backgroundColor:'#4b87a6', fontWeight:600, mt:-2}}
            >
              <PictureAsPdfIcon sx={{ mr: 1 }} />
              Download Broucher
            </Button>
          </Card>

          {/* Modal for Form */}
          <Modal
            open={open}
            onClose={handleClose} // Close modal on background click or escape key
            aria-labelledby="form-modal-title"
            aria-describedby="form-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography id="form-modal-title" variant="h6" sx={{ mb: 2 }}>
                Please Fill Out the Form
              </Typography>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={Boolean(errors.name)}
                helperText={errors.name}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Submit and Download
              </Button>
            </Box>
          </Modal>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WaveSVG;
