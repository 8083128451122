import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./component/Page/Home"
import Lagin from "./component/Login/Lagin"
// import Practice from './component/Practice';
// import Functionality from './component/Page/Functionality';
import Allservices from './component/Page/servicespage/Allservices';
import Allblogs from './component/Page/blogpages/Allblogs';
import Allabout from './component/Page/aboutus/Allabout';
import Allcontact from './component/Page/contactus/Allcontact';
import Allclient from './component/Page/clientpages/Allclient';
import Gallery from './component/Page/Gallery';
import Practice from './component/Practice';
import { Grid } from '@mui/material';
import Allweb from './component/Training/webdevelopment/Allweb';
import Alldigital from './component/Training/digitalmarketing/Alldigital';
import Alltest from './component/Training/softwaretesting/Alltest';
import Allseo from './component/Page/servicespage/SEO/Allseo';
import Allsocial from './component/Page/servicespage/Socialmedia/Allsocial'
import Allcontent from './component/Page/servicespage/Content/Allcontent';
import Allads from './component/Page/servicespage/ads/Allads';
import Allemail from './component/Page/servicespage/Email/Allemail';
import Allafi from './component/Page/servicespage/afrfiliated/Allafi';
import Allinflu from './component/Page/servicespage/influencer/Allinflu';
import Seopages from './component/Training/digitalmarketing/seo/Seopages';
import Contentpages from './component/Training/digitalmarketing/content/Contentpages';
import Ppcpages from './component/Training/digitalmarketing/ppc/Ppcpages';
import Emailpages from './component/Training/digitalmarketing/email/Emailpage';
import Metapages from './component/Training/digitalmarketing/meta/Metapages';
import Socialmediapages from './component/Training/digitalmarketing/socialmedia/Socialmedia';
import ScrollToTop from './component/Page/ScrollTop';
import Allpython from './component/Training/webdevelopment/Frontend.js/Python/Allpython';
import Homepage from './component/Training/graphicdesign/Homepage';

function App() {
  return (
    <Grid sx={{ overflowX: 'hidden', }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Lagin />} />
          <Route path="/services-page" element={<Allservices />} />
          <Route path="/allblogs" element={<><Allblogs /> <ScrollToTop /></>} />
          <Route path="/allabout" element={<Allabout />} />
          <Route path="/allcontact" element={<Allcontact />} />
          <Route path="/allclient" element={<Allclient />} />
          <Route path='/software-development' element={<Allweb />} />
          <Route path='/digital-marketing' element={
            <>
              <Alldigital />
              <ScrollToTop />
            </>
          } />
          <Route path='/software-testing' element={<Alltest />} />
          <Route path='/graphic-design' element={<Homepage />} />
          <Route path='/gallery' element={<Gallery />} />

          {/* Services pages */}
          <Route path='/allseo' element={<Allseo />} />
          {/* <Route path='/allsocial' element={<Allsocial/>}/> */}
          <Route path='/allcontent' element={<Allcontent />} />
          <Route path='/allads' element={<Allads />} />
          <Route path='/allemail' element={<Allemail />} />
          <Route path='/allafi' element={<Allafi />} />
          <Route path='/allinflu' element={<Allinflu />} />

          {/* Training pages Digital marketing */}

          <Route path='/seo-pages' element={ <>
              <Seopages />
              <ScrollToTop />
            </>} />
          <Route path='/content-pages' element={ <>
              <Contentpages />
              <ScrollToTop />
            </>} />
          <Route path='/ppc-pages' element={ <>
              <Ppcpages />
              <ScrollToTop />
            </>} />
          <Route path='/email-pages' element={ <>
              <Emailpages />
              <ScrollToTop />
            </>} />
          <Route path='/meta-pages' element={ <>
              <Metapages />
              <ScrollToTop />
            </>} />
          <Route path='/social-pages' element={ <>
              <Socialmediapages />
              <ScrollToTop />
            </>} />

{/* all software pages  */}
<Route path='/python-page' element={
  <>
  <Allpython/>
  </>
} />

        </Routes>
      </BrowserRouter>
    </Grid>
    // <> 
    // <Practice/>
    // </>

  );
}

export default App;
