import React from 'react'
import Python1 from './Python1'
import Python2 from './Python2'
import Python3 from './Python3'
import Header1 from '../../../../Page/Header1'
import Footer from '../../../../Page/Footer'
const Allpython = () => {
  return (
    <div>
      <Header1/>
      <Python1/>
      {/* <Python2/> */}
      {/* <Python3/> */}
      <Footer/>
    </div>
  )
}

export default Allpython
