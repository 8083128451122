import React from 'react';
import { Card, CardContent, CardMedia, Grid, Typography, Box, Rating } from '@mui/material';
import { motion } from 'framer-motion';
import graphic from '../../../assets/Graphic/graphic.png';
import advance from '../../../assets/Graphic/advanced.png';
import dgraph from '../../../assets/Graphic/3dgraph.png';

const Graphicscards = () => {
  const cardData = [
    {
      title: 'Graphic Design 101',
      image: graphic, 
      rating: 4.5,
    },
    {
      title: 'Advanced Illustration',
      image: advance,
      rating: 4.0,
    },
    {
      title: '3D Animation Basics',
      image: dgraph,
      rating: 4.7,
    },
  ];

  return (
    <Grid container spacing={3} sx={{ width: '100%', p: 5, background: '#090e34', m: 0 }}>
      {/* Title Section */}
      <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 , mt:5}}>
      <Typography
  variant="h2"
  sx={{
    fontSize: { xs: '32px', sm: '38px', md: '40px' },
    fontWeight: 600,
    color: '#e349d4',
    
  }}
>
  Popular Graphic Design
</Typography>

      </Grid>

      {/* Cards Section */}
      <Grid container spacing={2} justifyContent="center">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} mt={5}>
            <motion.div
              animate={{
                y: ['0%', '-5%', '0%'],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            >
              <Card
                sx={{
                  backgroundColor: 'white',
                  color: '#11163a',
                  height: '100%',
                  width: '100%',
                  boxShadow: 3,
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={card.image}
                  alt={card.title}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: '16px', md: '20px' } }}
                    gutterBottom
                  >
                    {card.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                      value={card.rating}
                      precision={0.5}
                      readOnly
                      sx={{ color: 'gold' }}
                    />
                    <Typography variant="body2" sx={{ ml: 1, fontSize: { xs: '12px', md: '14px' } }}>
                      {card.rating}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Graphicscards;
