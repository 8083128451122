import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Card, Divider, Grid } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Updated dummy data with multiple content items per tab
const tabData = [
    { label: "Module 1: Introduction to Python     ", content: ["Introduction to Python", "Setting up Python environment", "First Python program: Hello, World!", "Variables, data types, and basic operators", "Understanding Python's syntax and indentation"] },
    { label: "Module 2: Control Flow and Functions", content: ["Conditional statements (if, elif, else)", "Looping structures (for, while)", "Functions: defining and calling functions", "Arguments, return values, and lambda functions"] },
    { label: "Module 3: Data Structures and Algorithm", content: ["Lists, tuples, and sets", "Dictionaries in Python", "List comprehensions", "Handling exceptions (try, except, finally)"] },
    { label: "Module 4: Object-Oriented Programming", content: ["Introduction to OOP", "Classes and objects", "Methods, constructors, and destructors", "Inheritance and polymorphism", "Encapsulation and abstraction"] },
    { label: "Module 5: Working with Files and Code", content: ["File input and output (read, write, append)", "File handling modes", "Working with CSV, JSON, and text files", "Context managers (with statement)"] },
    { label: "Module 6: Python Libraries and Modules", content: ["Importing libraries", "Using built-in libraries (math, datetime, random)", "Installing third-party libraries (pip)" , "Understanding modules and packages"] },
    { label: "Module 7: Advanced Python Concepts", content: ["Decorators", "Generators and Iterators", "Regular expressions", "Multi-threading and concurrency basics"] },
    { label: "Module 8: Data Analysis with Python ", content: ["Introduction to NumPy and Pandas", "Data manipulation and cleaning", "Data visualization using Matplotlib", "Basic data analysis projects"] },
    { label: "Module 9: Introduction to Web- Python ", content: ["Introduction to Flask/Django", "Setting up a basic web app", "Handling HTTP requests and responses", "Templating and static files"] },
    { label: "Module 10: Python for Automations", content: ["Introduction to automation with Python", "Automating simple tasks (file handling, renaming files)", "Web scraping using BeautifulSoup and Requests", "Working with APIs for automation", "Scheduling tasks with schedule and time modules"] },
    { label: "Module 11: Db Interaction with Python", content: ["Introduction to databases (SQL vs NoSQL)", "Connecting to databases (SQLite, MySQL, PostgreSQL)", "CRUD operations (Create, Read, Update, Delete)", "Using SQLAlchemy (ORM)", "Querying and manipulating data in databases" ] },
    { label: "Module 12: Testing and Debugging Python ", content: [ "Introduction to testing in Python", "Writing unit tests using unittest and pytest","Debugging tools and techniques", "Test-driven development (TDD)", "Mocking and patching" ] },
    { label: "Module 13: Python for Automations", content: ["Introduction to XML, JSON, and CSV formats", "Parsing and generating data formats","Working with JSON APIs and RESTful services", "Using Python’s csv and json modules for data manipulation", "Serialization and deserialization of data" ] },
    { label: "Module 14: Python for Machine Learning ", content: ["Introduction to machine learning concepts", "Using Scikit-learn for basic algorithms ", "Introduction to datasets and feature engineering", "Understanding model training and evaluation", "Exploring simple machine learning projects" ] },
];


// function SyllabusDownload({ link }) {
//     const handleViewPDF = (link) => {
//       if (link) {
//         window.open(link, '_blank', 'noopener,noreferrer');
//       } else {
//         alert('Syllabus link is not available');
//       }
//     };
//     return (
//         <Box sx={{ textAlign: 'center', mt: 3 }}>
//           <Button variant="contained" onClick={() => handleViewPDF(link)}>
//             <PictureAsPdfIcon /> Download Syllabus
//           </Button>
//         </Box>
//       );
//     }
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ height: "100%", overflowY: "auto" }} // Scroll within TabPanel content
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isFirstItemClicked, setIsFirstItemClicked] = React.useState(false);
    const handleItemClick = (index) => {
        if (index === 0) {
            setIsFirstItemClicked(true); // First item clicked
        } else {
            setIsFirstItemClicked(false); // Reset for other items
        }
    };
    const handleViewPDF = (pdfLink) => {
        window.open(pdfLink, "_blank"); // Opens the PDF in a new tab
      };
     

    return (
        <Box
            sx={{
                flexGrow: 1,
                // bgcolor: "background.paper",
                display: "flex",
                height: "90vh", // Increased container height
            
            }}
        >
            <Grid
                container
                sx={{
                    p: 5,
                    backgroundColor: "#fff",
                    gap: 5,
                    height: "100%",
                    overflow: "hidden",
                    
                }}
            >
                <Grid item xs={12} sx={{ backgroundColor: "#02d5d1", textAlign: "center", height: "70px" }}>
                    <Typography sx={{ fontSize: "30px", mt: 2 }}>Python Courses Syllabus</Typography>
                </Grid>

                <Grid
                    container
                    sx={{
                        padding: {md:'60px 140px',},
                        backgroundColor: "#edf4ff",
                        gap: 5,
                        height: "90%",
                        overflow: "hidden",
                        mt: {md:-5,xs:5, sm:3},
                        flexDirection: { xs: 'row', sm:'row', md: 'column' },
                    }}
                >
                    <Card
                        sx={{
                            borderRight: 1,
                            backgroundColor: "white",
                            height: "100%", // Increased card height
                            overflowY: "auto", // Enables scrolling for the tab list
                            width: "45%",
                            borderRadius:'5px'

                        }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                '& .MuiTab-root': { // General tab styling
                                    fontSize: '22px',
                                    color:'black',
                                    transition: 'background-color 0.3s ease',
                                    minHeight: '80px',
                                    textAlign: 'left', // Align the text to the left
                                    paddingLeft: '16px', // Optional: Add padding if necessary to improve the alignment
                                    textTransform: 'none',
                                },
                                '& .Mui-selected': { // Style for the selected tab
                                    backgroundColor: '#D9EEE1', // Light blue background like the image
                                    color: 'blue', // Darker blue for text (optional for better contrast)
                                },
                            }}
                        >
                            {tabData.map((tab, index) => (
                                <Tab key={index} label={tab.label} {...a11yProps(index)} />
                            ))}
                        </Tabs>

                    </Card>


                    <Card
                        sx={{
                            flex: 1,
                            height: "90%", // Increased card height
                            width: "45%",
                            overflowY: "auto", // Enables scrolling for content in the tab panel
                            p: 3,
                            backgroundColor: "#D9EEE1",
                            textTransform: 'none',
                            
                        }}
                    >
                        {tabData.map((tab, index) => (
                            <TabPanel value={value} index={index} key={index}>
                                <Typography variant="h6" sx={{ fontSize: '25px', mb: 2, fontFamily: 'inherit', }}>{tab.label}</Typography>
                                <Divider sx={{ mb: 2 }} />
                                {tab.content.map((item, i) => (
                                    <Typography key={i} sx={{ fontSize: '18px', mt: 1, fontFamily: 'sans-serif' }}>
                                        {/* Flex container for aligning icon and text */}
                                        <span
                                            style={{
                                                marginLeft: '5px',
                                                fontSize: '20px',
                                                display: 'inline-flex', // Use inline-flex to align elements in one line
                                                alignItems: 'left', // Vertically align icon and text
                                                padding: '2px 5px',
                                                transition: 'background-color 0.3s ease', // Smooth transition
                                            }}
                                            className="hover-item"
                                        >
                                            {/* ArrowRightIcon aligned to the text */}
                                            <ArrowRightIcon sx={{ mr: 1 }} /> {/* `mr: 1` adds margin-right to space between icon and text */}
                                            {item}
                                        </span>
                                    </Typography>
                                    
                                ))}
                                {/* <SyllabusDownload link={tab.link} /> */}
                            </TabPanel>
                        ))}

                        <Box sx={{textAlign:'center' , mt:-5,textTransform: 'none', }}>
                            
                            <Button variant='contained' onClick={() => handleViewPDF( "")}> <PictureAsPdfIcon/>  Download Syllabus </Button>
                        </Box>
                    </Card>

                </Grid>
            </Grid>
        </Box>
    );
}
