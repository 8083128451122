import React, { useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import { Typography, Grid, Box } from "@mui/material";
import girl1 from "../../../assets/Graphic/bg1.png";
import image1 from "../../../assets/Graphic/review.png";
import image2 from "../../../assets/Graphic/review2.png";
import image3 from "../../../assets/Graphic/review3.png";

const testimonials = [
  {
    name: "Priya Gowda",
    image: image1,
    feedback:
      "The course is a perfect blend of theory and hands-on practice. I was able to create professional-level designs by the end of the sessions.",
  },
  {
    name: "Avi Acharya",
    image: image2,
    feedback:
      "Loved the structured approach! The instructors are industry experts who make complex concepts easy to understand.",
  },
  {
    name: "Rakesh",
    image: image3,
    feedback:
      "A fantastic experience! The course covered everything from design basics to advanced tools like Photoshop and Illustrator.",
  },
  {
    name: "Meera",
    image: image1,
    feedback:
      "The mentorship and career guidance were outstanding. I landed my first design gig within two months of completing the course.",
  },
  {
    name: "Rahul",
    image: image3,
    feedback:
      "The history and evolution module was eye-opening, and the practical sessions were engaging. Worth every penny.",
  },
  {
    name: "Nayana Swamy",
    image: image1,
    feedback:
      "The 3D animation basics module was my favorite! It gave me the confidence to dive into motion graphics.",
  },
  {
    name: "Siddharth Gowda",
    image: image2,
    feedback:
      "The interactive assignments and real-world projects helped me build an impressive portfolio. Highly recommend.",
  },
];

export default function Testimonials() {
  const scrollerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollerRef.current) {
        scrollerRef.current.scrollTop += 1; // Adjust scroll speed
      }
    }, 50); // Adjust scroll timing

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${girl1})`,
        padding: "20px",
        minHeight: "100vh",
        color: "#fff",
        textAlign: "center",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: "#fff",
          fontWeight: 600,
          fontSize: { xs: "20px", sm: "24px" , md:'30px'},
          mt:5
        }}
      >
        Discover our students' journeys
      </Typography>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{
          mb: 4,
          opacity: 0.8,
          fontSize: { xs: "14px", sm: "16px" },
        }}
      >
        Discover the glowing feedback from our delighted Students worldwide.
      </Typography>
      <Box
        ref={scrollerRef}
        sx={{
          overflowY: "auto",
          maxHeight: "70vh", // Restrict the height for scrolling
          scrollBehavior: "smooth",
          padding: "10px",
          "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
        }}
      >
        <Grid container spacing={2}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  backgroundColor: "#1C1E3F",
                  borderRadius: "8px",
                  padding: {xs:"16px", sm:'16px', md:'10px 180px'},
                  boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
                  textAlign: "left",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar
                    src={testimonial.image}
                    alt={testimonial.name}
                    sx={{ width: 40, height: 40, mr: 2 }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      fontSize: "18px",
                    }}
                  >
                    {testimonial.name}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ccc",
                    lineHeight: 1.5,
                    fontSize: "18px",
                  }}
                >
                  "{testimonial.feedback}"
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
