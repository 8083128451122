import { Grid } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
// import banner1 from '../../../assets/gbanner.png';


// Component to create fading, curved lines with shadow based on mouse movement
function FadingLines() {
  const [lines, setLines] = useState([]);
  const lastMousePosition = useRef([0, 0]);
  const timeoutRef = useRef(null);

  // Number of lines to create at each mouse position
  const linesPerMove = 5;

  // Update lines on mouse move
  const handleMouseMove = (event) => {
    const mouseX = (event.clientX / window.innerWidth) * 10 - 5; // Normalize X
    const mouseY = -(event.clientY / window.innerHeight) * 10 + 5; // Normalize Y

    // Generate multiple curved lines with shadows
    const newLines = Array.from({ length: linesPerMove }, (_, i) => {
      const angle = (i / linesPerMove) * Math.PI * 2; // Spread lines evenly
      const offsetX = 0.5 * Math.cos(angle);
      const offsetY = 0.5 * Math.sin(angle);

      // Create start and end points
      const start = new THREE.Vector3(mouseX, mouseY, 0); // Start from mouse pointer
      const end = new THREE.Vector3(mouseX + offsetX, mouseY + offsetY, 0);

      // Control points to create a curve
      const controlPoint1 = new THREE.Vector3(
        mouseX + offsetX * 2,
        mouseY + offsetY * 2,
        0.5
      );
      const controlPoint2 = new THREE.Vector3(
        mouseX - offsetX * 2,
        mouseY - offsetY * 2,
        -0.5
      );

      const curve = new THREE.CubicBezierCurve3(start, controlPoint1, controlPoint2, end);
      const points = curve.getPoints(20);

      return {
        points,
        opacity: 1, // Main line initial opacity
      };
    });

    // Add the new lines to the state
    setLines((prevLines) => [...prevLines, ...newLines]);

    // Clear any previous fade-out timeout
    clearTimeout(timeoutRef.current);

    // Start fade-out after mouse stops moving
    timeoutRef.current = setTimeout(() => {
      fadeOutLines();
    }, 500);
  };

  // Fade out lines over time by reducing their opacity
  const fadeOutLines = () => {
    setLines((prevLines) =>
      prevLines
        .map((line) => ({
          ...line,
          opacity: line.opacity - 0.02, // Gradual fade
        }))
        .filter((line) => line.opacity > 0) // Keep lines until fully faded
    );
  };

  // Add an event listener for mouse movement
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Continuously fade lines out in the animation loop
  useFrame(() => {
    fadeOutLines();
  });

  return (
    <>
      {lines.map((line, index) => (
        <Line
          key={index}
          points={line.points}
          color="purple"
          opacity={line.opacity}
          transparent
          lineWidth={1.5}
        />
      ))}
    </>
  );
}

const Coursesdetail = () => {
  return (
    <Grid
      container
      sx={{
        // backgroundColor:'#090e34',
        // background: `url(${banner1}) no-repeat center center`,
        height: '100vh', // Full viewport height
        position: 'relative',
        overflow: 'hidden', // Prevent scrollbars if elements exceed bounds
      }}
    >
      <Canvas
        camera={{ position: [0, 0, 5], fov: 20 }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1, // Canvas goes behind the content
        }}
      >
        <ambientLight intensity={3.5} />
        <FadingLines />
      </Canvas>
      <Grid
        item
        xs={12}
        sx={{
          zIndex: 1, // Content above the Canvas
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        hello
      </Grid>
    </Grid>
  );
};

export default Coursesdetail;
