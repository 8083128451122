import { Grid } from '@mui/material';
import React from 'react';


const WaveSVG = () => {
  return (
    <Grid style={{ width: '100%', height: '50vh', overflow: 'hidden' ,backgroundColor: '#D9EEE1'}}>
      <svg
        width="100%"
        height="70"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ backgroundColor: '#D9EEE1' }}
      >
        <path
          d="M0,0 L110,0 C35,220 35,0 0,90z"
          fill="#fff"
        />
      </svg>
      <Grid container>
        Hello
      </Grid>

    </Grid>
    
  );
};

export default WaveSVG;
