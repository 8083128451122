import React, { useState } from 'react';
import { Grid, Box, Typography, Card, CardContent, Button, CardMedia } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactTyped } from 'react-typed';
import gd from '../../../assets/Graphic/overview.png'
import ct from '../../../assets/Graphic/colorteraphy.png'
import tool from '../../../assets/Graphic/tools (1).png'
import logo from '../../../assets/Graphic/logo.png'
import digital from '../../../assets/Graphic/digita.png'
import animation from '../../../assets/Graphic/animation.png'
import porto from '../../../assets/Graphic/porto.png'
import practical from '../../../assets/Graphic/practical.png'

const cardsData = [
    {
      image: gd,
      blockText: 'Overview of Graphic Design',
      points: [
        'Knowing the Fundamentals of Design',
        'The Origins and Development of Graphic Design ',
        'The Function of a Graphic Designer'
      ],
    },
    
    {
        image: tool,
      blockText: 'Tools for Design Software',
      points: [
        'Adobe Photoshop: Editing and Modifying Images',
        'Adobe Illustrator: Illustrations & Vector Graphics',
        'Adobe InDesign: Publishing and Layouts',
        'Overview of Canva and CorelDRAW'
      ],
    },
    {
        image: animation,
      blockText: 'Animation and Motion Graphics',
      points: [
        'Fundamentals of Graphic Design Animation',
        'Overview of Adobe After Effects',
        'Making Animated Ads and Logos',
        
      ],
    },
    {
        image: logo,
      blockText: 'Logo Design for Branding and Identity',
      points: [
        'Branding Color Schemes',
        'Making Stationery, Flyers, and Business Cards',
        'Creating Posts for Social Media'
      ],
    },
    
    {
        image: digital,
      blockText: 'Digital Drawing Tools and Illustration Software',
      points: [
        'Raster versus Vector Graphics',
        'Making Original Illustrations and Icons',
       
      ],
    },
    
    {
        image: ct,
      blockText: ' Design Elements and Principles',
      points: [
        'Psychology and Color Theory',
        'Fonts & Typography',
        'Arrangement and Content',
        'Balance, Space, and Shapes'
      ],
    },

    {
        image: porto,
      blockText: 'Freelance work and portfolio',
      points: [
        'Developing a Graphic Design Portfolio,',
        'Communicating with Clients, and Managing Projects',
        'Platforms and Opportunities for Freelancing'
      ],
    },
    {
        image: practical,
      blockText: 'Practical Projects',
      points: [
        'Creating for Marketing Initiatives',
        'Graphic Projects for Social Media',
        'Promotions and Posters for Events'
      ],
    },
  ];
const HoverCards = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <Grid container spacing={6} sx={{ padding: '10px 50px', height: { md: '110vh', sx: '150vh' },background:'#090e34' }}>
      {/* Title Section */}
      <Grid item xs={12}>
      <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', marginLeft: { md: 3, xs: 0 } , mt:5,}} >
        <ReactTyped
          strings={["Graphic Design Course Topics"]}
          typeSpeed={60}
          backSpeed={50}
          loop
          style={{ color: 'blue', fontWeight: 'bold', fontSize: '40px' }} 
        />
      </Typography>
      </Grid>

      {/* Cards Section */}
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index} mb={3}>  {/* Reduced mb from 7 to 3 */}
        <Card
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '90%',
            borderRadius: '8px',
            boxShadow: '6px 4px 8px rgba(0, 0, 0, 0.8)',
            cursor: 'pointer',
          }}
          onMouseEnter={() => setHoveredCard(index)}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <CardMedia
            component="img"
            height="300"
            width='90%'
            image={card.image}
            sx={{ objectFit: 'cover' }}
          />
          <CardContent
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: hoveredCard === index ? '300px' : '35px',
              backgroundColor: '#E0E0E0',
              color: 'blue',
              display: 'flex',
              flexDirection: 'column',
              alignItems: hoveredCard === index ? 'flex-start' : 'left',
              justifyContent: 'center',
              transition: 'all 0.3s ease-in-out',
              borderTopLeftRadius: '30px',
            //   borderbuRightRadius: '30px',
            }}
          >
            <Typography
              sx={{
                fontWeight:600,
                fontSize: '20px',
              }}
            >
              {card.blockText}
            </Typography>
      
            {hoveredCard === index && (
              <Box
                sx={{
                  mt: 2,
                  mb:-9,
                  color: 'black',
                  fontSize: '14px',
                  transition: 'all 0.3s ease-in-out',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                {card.points.map((point, idx) => (
                  <Typography key={idx} sx={{ mb: 1 }}>
                    • {point}
                  </Typography>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      
      ))}
    </Grid>
  );
};

export default HoverCards;
